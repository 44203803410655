<template>
  <div class="popup-banners-list">
    <HeadSection title='Pop-up banner' permissionOfSection="popup-banners">
      <template #actions>
        <a-col :span="14" class="text-right">
          <a-button v-if="!isViewscreen" type="primary" size="large" style="font-size: 16px; font-weight: 300; border-radius: 2px"
            @click="createPopup" :disabled="!createPermission">
            <a-icon type="plus" /> Create new
          </a-button>
          <a-button v-else type="primary" size="large" style="font-size: 16px; font-weight: 300; border-radius: 2px"
            @click="addPopupToViewscreen" :disabled="!popupToViewscreenArray.length">
            <a-icon type="plus" /> Save
          </a-button>
        </a-col>
      </template>
    </HeadSection>
    <MainContainer :loading="userLoading" permissionOfSection="popup-banners">
      <div class="d-flex align-items-center mb-4">
        <AppSelect v-model="filters.applicationId" :apps="apps" class="mr-3" @change="listUpdate" :disabled="loading || popupsLoading"
          style="width: 220px;" />
        <LocalizationsSelect v-model="filters.localizationId" :localizations="languagesOptions" :disabled="loading || popupsLoading"
          class="mr-4" @change="listUpdate" />
        <ActiveStatus v-model="filters.active" @change="listUpdate" :disabled="loading || popupsLoading" />
        <a-input-search style="width: 200px; height: 40px; margin-top: auto;" size="large" v-model="filters.name"
          placeholder="Input search text" enter-button @search="listUpdate" :disabled="loading || popupsLoading" class="ml-auto" />
      </div>
      <div v-if="!popupsLoading" class="grid">
        <draggable v-if="popupBanners.length" v-model="popupBanners" v-bind="dragOptions" @start="drag = true"
          @end="sortPopups()" tag="div">
          <transition-group>
            <PopBannerCard v-for="(popupBanner) in popupBanners"
            :popupInfo="popupBanner"
            :editPermission="editPermission"
            :languages="languages"
            :key="popupBanner.id"
            :isPopupChecked="popupToViewscreenArray.includes(popupBanner.id)"
            :isCheckboxDisabled="isCheckboxDisabled(popupBanner.id)"
            :isViewscreen="isViewscreen"
            @addToViewscreenArray="handleAddToViewscreenArr(popupBanner.id)"
            @deleteFromViewscreenArray="handleDeleteViewscreeArr(popupBanner.id)"
            @openFullInfo="openPopupModal(popupBanner)"
            @openEdit="openPopupPage(popupBanner.id)"/>
          </transition-group>
        </draggable>
        <EmptyState v-else-if="!popupsLoading && popupBanners.length === 0" :text="emptyStateText" :isAddDisabled="!createPermission" textBtn="Add" @add="createPopup" />
      </div>
      <LoadingState v-else/>
    </MainContainer>
    <a-modal v-model="isPopupModalOpen" title="Pop-up banner info" :footer="null" width="920px" :destroyOnClose="true">
      <PopInfoModal :popupInfo="popupObject" :languages="languages" @close="closePopupModal" />
    </a-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AppSelect from '@/components/custom/filters/appSelect.vue'
import LocalizationsSelect from '@/components/custom/filters/localizationsSelect.vue'
import ActiveStatus from '@/components/custom/filters/activeStatus.vue'
import HeadSection from '@/components/main/headSection'
import MainContainer from '@/components/main/mainContainer'
import LoadingState from '@/components/custom/loadingState.vue'
import EmptyState from '@/components/custom/emptyState.vue'
import PopBannerCard from '@/views/apps/popup-banners/components/popBannerCard.vue'
import PopInfoModal from '@/components/custom/modals/popInfoModal.vue'
import PopupService from '@/services/api/apiPopupService'
// import ViewscreenService from '@/services/api/apiViewscreenService'
import accessMix from '@/services/accessmix.js'
import apiClient from '@/services/axios'
import draggable from 'vuedraggable'
export default {
  name: 'Popup-list',
  mixins: [accessMix],
  components: {
    HeadSection,
    MainContainer,
    AppSelect,
    ActiveStatus,
    LocalizationsSelect,
    draggable,
    PopBannerCard,
    PopInfoModal,
    LoadingState,
    EmptyState,
  },
  props: {
    createPermission: {
      type: Boolean,
      default: false,
    },
    editPermission: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      languagesLoading: false,
      popupBanners: [],
      drag: false,
      loading: false,
      popupsLoading: true,
      apps: undefined,
      languages: [],
      filters: {
        applicationId: undefined,
        localizationId: undefined,
        active: true,
        name: undefined,
      },
      isPopupModalOpen: false,
      popupObject: undefined,
      popupToViewscreenArray: [],
    }
  },
  computed: {
    ...mapState(['user']),
    userLoading() {
      return this.$store.state?.user?.loading || false
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    languagesOptions() {
      return this.languages.map(function (row) {
        return { value: row.id, label: row.desc }
      })
    },
    isViewscreen() {
      return !!this.$route.params.sectionId
    },
    isChangeExistingPopup() {
      return this.$route.params.id !== undefined
    },
    emptyStateText() {
      return this.filters.name?.length ? 'Oops! Nothing matches your search.' : 'Add first popup-banner'
    },
  },
  async created() {
    await this.fetchApplications()
    await this.fetchLocalizations()
    await this.listUpdate()
  },
  watch: {
  },
  methods: {
    createPopup() {
      this.$router.push('/popup-banners/create')
    },
    openPopupPage(id) {
      this.$router.push(`/popup-banners/edit/${id}`)
    },
    openPopupModal(popupObject) {
      this.popupObject = popupObject
      this.isPopupModalOpen = true
    },
    closePopupModal() {
      this.isPopupModalOpen = false
    },
    isCheckboxDisabled(id) {
      if (!this.popupToViewscreenArray.length) {
        return false
      }
      return !this.popupToViewscreenArray.includes(id)
    },
    handleAddToViewscreenArr(id) {
      this.popupToViewscreenArray.push(id)
    },
    handleDeleteViewscreeArr(id) {
      const deletedIndex = this.popupToViewscreenArray.findIndex(item => item === id)
      if (deletedIndex !== -1) {
        this.popupToViewscreenArray.splice(deletedIndex, 1)
      }
    },
    redirectToMain() {
      this.$router.push(`/main/${this.$route.params.viewscreenId}`)
    },
    async listUpdate() {
      try {
        this.popupsLoading = true
        await this.getPopups()
      } catch (e) {
        this.$notification.error({
          message: e.response.data.error.message,
        })
      } finally {
        this.popupsLoading = false
      }
    },
    async getPopups() {
      const { name, active, localizationId, applicationId } = this.filters
      const searchParams = new URLSearchParams()
      searchParams.append('is_active', active)

      if (typeof localizationId !== 'undefined') {
        searchParams.append('lang_id', localizationId)
      }

      if (typeof applicationId !== 'undefined') {
        searchParams.append('app_id', applicationId)
      }
      if (typeof name !== 'undefined') {
        searchParams.append('name', name)
      }
      try {
        const popupResponse = await PopupService.getPopups(searchParams)
        this.popupBanners = popupResponse.data.data
        if (this.isChangeExistingPopup) {
          this.popupBanners = this.popupBanners.filter(popupBanner => String(popupBanner.id) !== this.$route.params.id)
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
      }
    },
    async fetchApplications() {
      this.loading = true
      try {
        const url = '/admin/applications'
        const response = await apiClient.get(url)
        if (response.data.data) {
          this.apps = response.data.data
          this.apps.forEach(item => {
            if (item.is_default === 1) {
              this.filters.applicationId = item.application_id
            }
          })
        }
      } finally {
        this.loading = false
      }
    },
    async fetchLocalizations() {
      this.loading = true
      const url = '/admin/localizations'
      try {
        const response = await apiClient.get(url)
        this.languages = response.data.data
      } catch (error) {
        console.log('Error while trying to get locales', error)
      } finally {
        this.loading = false
      }
    },
    async sortPopups() {
      const popupSort = this.popupBanners.map((popupBanner, index, promoArr) => {
        return {
          popup_banner_id: popupBanner.id,
          sort: index + 1,
        }
      })
      const sort = { sort: popupSort }
      try {
        await PopupService.sortPopups(this.filters.applicationId, sort)
        this.$notification.success({
          message: 'Sucessfully sorted',
        })
      } catch (error) {
        console.log(error)
      }
      this.drag = false
    },
    async addPopupToViewscreen() {
      if (this.isChangeExistingPopup) {
        await this.deletePopupSectionItem()
      }
      await this.addPopupSectionItem()
    },
    // async addPopupSectionItem () {
    //   this.loading = true
    //   try {
    //     const response = await ViewscreenService.addViewscreenSectionItem(this.$route.params.sectionId, this.popupToViewscreenArray)
    //     if (response.data.data) {
    //       this.$notification.success({
    //         message: 'Pop up has been successfully added to the viewscreen section',
    //       })
    //     }
    //     this.redirectToMain()
    //   } catch (e) {
    //     this.$notification.error({
    //       message: 'Error while adding pop-up banner to viewscreen section',
    //       description: e.response.data.error.message,
    //     })
    //     this.loading = false
    //   } finally {
    //     this.loading = false
    //   }
    // },
    // async deletePopupSectionItem() {
    //   this.loading = true
    //   try {
    //     await ViewscreenService.deleteViewscreenSectionItem(this.$route.params.sectionId, this.$route.params.id)
    //   } catch (error) {
    //     this.$notification.error({
    //       message: 'Error while deleting pop-up banner',
    //       description: error.message,
    //     })
    //   } finally {
    //     this.loading = false
    //   }
    // },
  },
}
</script>
<style lang="scss">
.popup-banners-list {

  .grid>div>span {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    // grid-template-columns: repeat(auto-fill, 380px);
    gap: 24px;
    padding: 24px 0;
    background: #fff;
  }

  .flip-grid-move {
    transition: transform 0.5s;
    cursor: pointer;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  @media screen and (max-width: 1473px) {
    .banner-slider {
      grid-template-columns: repeat(3, 250px);
    }

    .banner-slider .flex-column {
      width: 100px !important;
    }
  }

  @media screen and (max-width: 1200px) {
    .banner-slider {
      grid-template-columns: repeat(auto-fit, 250px);
    }
  }

}
</style>
