<template>
  <div class="pop-card">
    <div class="pop-card__card bg-white" :class="{ 'pop-card__card--selected': isPopupChecked }" >
      <div class="pop-card__actions">
        <div>
          <a-select :value="selectedLanguage" size="small" @change="handleLangChange"
            class="lang-select">
            <a-select-option v-for="lang in langOptions" :key="lang.value">
              {{ lang.name }}
            </a-select-option>
          </a-select>
        </div>
        <div>
          <a-button type="link" :disabled="!editPermission" @click="openEditPage"><a-icon type="edit" class="text-primary font-size-16" />Edit promo</a-button>
        </div>
        <a-checkbox v-if="isViewscreen" :checked="addToViewscreen" :disabled="isCheckboxDisabled" @change="handleViewscreenChange"/>
      </div>
      <div class="pop-card__content" @click="openFullInfoModal">
        <div>
          <img class="pop-card__image" :src="selectedInfo.path" alt="" />
        </div>
        <div class="pop-card__left">
          <div class="mb-2">
            <div class="pop-card--title">Title</div>
            <span class="pop-card__pop-title pop-card__text">{{ selectedInfo.title }}</span>
          </div>
          <div class="mb-2">
            <div class="pop-card--title">Text</div>
            <span class="pop-card__description pop-card__text">{{ selectedInfo.description }}</span>
          </div>
          <div>
          <div class="pop-card--title">Apps</div>
          <div style="line-height: 2.1rem">
            <a-tag style="margin-right: 15px; padding: 1px 8px; border-radius: 2px"
              v-for="app in popupInfo.apps.slice(0, 1)" :key="app.id">
              {{ app.name }}
              <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="ml-1 text-success" />
              <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="ml-1 text-primary" />
              <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="ml-1 text-default" />
            </a-tag>
            <a-tag v-if="popupInfo.apps.length > 1">
              ...
            </a-tag>
          </div>
        </div>
        </div>
      </div>
      <div class="pop-card__footer">
        <div class="d-flex">
          <div class="text-uppercase mr-auto">
            {{ formatDate(popupInfo.start_date) }} -
            <span>{{ formatDate(popupInfo.end_date) }}</span>
          </div>
          <div class="d-flex">
            <a-switch :default-checked="popupInfo.is_active" @change="popBannerActiveSwitch"
              :disabled="!editPermission" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import apiClient from '@/services/axios'
import PopupService from '@/services/api/apiPopupService'

export default {
  name: 'popBannerCard',
  props: {
    popupInfo: {
      type: Object,
    },
    editPermission: {
      type: Boolean,
    },
    languages: {
      type: Array,
    },
    isViewscreen: {
      type: Boolean,
      default: false,
    },
    isPopupChecked: {
      type: Boolean,
      default: false,
    },
    isCheckboxDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedLanguage: 'ru',
    addToViewscreen: false,
  }),
  computed: {
    selectedInfo() {
      return this.popupInfo.localizations[this.selectedLanguage]
    },
    langOptions() {
      const langKeys = Object.keys(this.popupInfo.localizations)
      return this.languages.filter(language => langKeys.includes(language.name)).map(language => ({
        name: language.desc,
        value: language.name,
      }))
    },
  },
  watch: {
    isPopupChecked(value) {
      this.addToViewscreen = value
    },
  },
  created() {
  },
  methods: {
    openFullInfoModal() {
      this.$emit('openFullInfo')
    },
    openEditPage() {
      this.$emit('openEdit')
    },
    formatDate: function (date) {
      return this.$moment(date).format('DD.MM.YY HH:mm')
    },
    handleLangChange(selectedLangKey) {
      this.selectedLanguage = selectedLangKey
    },
    handleViewscreenChange(event) {
      this.addToViewscreen = event.target.checked
      const action = event.target.checked ? 'addToViewscreenArray' : 'deleteFromViewscreenArray'
      this.$emit(action, this.popupInfo.id)
    },
    async popBannerActiveSwitch(checked) {
      try {
        const popupPayload = {
          is_active: checked,
        }
        const popupResponse = await PopupService.updatePopup(this.popupInfo.id, popupPayload)
        this.$notification.success({
          message: 'Active status changed',
          description: `${this.popupInfo.name} status have been changed`,
        })
        if (popupResponse.data.error) {
          const errorMessage =
            popupResponse.data.error?.message ||
            'Something went wrong while updating pop-up activity'
          this.$notification.error({
            message: errorMessage,
          })
          this.loading = false
          return
        }
      } catch (e) {
        const errorMessage =
          e.response.data?.error?.message ||
          'Something went wrong while updating activity'
        this.$notification.error({
          message: errorMessage,
        })
      }
    },
  },
}
</script>

<style lang='scss'>
.pop-card {

  &__card {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 2px;
    &--selected {
      box-shadow: 7px 7px 3px 0px rgba(20, 20, 29, 0.15);
    }
  }

  &__actions {
    display: flex;
    padding: 8px 10px 4px 8px;
    justify-content: space-between;
    align-items: center;

    .ant-btn {
      padding: 0 !important;
    }
  }

  &__content {
    flex: 1 1 calc(40% - 16px);
    display: flex;
    padding: 0 16px 16px;
  }

  &__image {
    margin-right: 16px;
    width: 92px;
    height: 148px;
    // object-fit: cover;
    // object-position: top;
  }

  &__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  &--title {
    color: #bfbfbf;
    font-weight: 300;
  }
  &__pop-title {
    -webkit-line-clamp: 1;
  }

  &__description {
    -webkit-line-clamp: 1;
  }

  &__lang-tabs {
    padding: 0 16px;
  }

  &__footer {
    border-top: 1px solid #E8E8E8;
    padding: 9px 16px;
  }

  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 !important;
  }
}
</style>
