<template>
  <div class="popup-modal">
    <div class="popup-modal__actions">
      <a-select :value="selectedLanguage" size="small" @change="handleLangChange" class="lang-select">
        <a-select-option v-for="lang in langOptions" :key="lang.value">
          {{ lang.name }}
        </a-select-option>
      </a-select>
    </div>
    <div class="popup-modal__content">
      <div class="popup-modal__image-container">
        <img class="popup-modal__image" :style="{ opacity: !isPopupDateValid ? '40%' : '100%' }"  :src="selectedInfo.path" :alt="selectedInfo.title" />
        <div v-if="isViewscreen" class="popup-modal__badge" :class="badgeClass">{{badgeText}}</div>
      </div>
      <div class="popup-modal__info-container">
        <div class="d-flex">
          <div class="popup-modal__util-width">
            <div class="popup-modal__title">Tech name</div>
            <span class="mr-2">{{ popupInfo.name }}</span>
            <a-tooltip title="Copy to clipboard" placement="right">
              <a href="javascript:;"><a-icon type="copy" class="font-size-16 text-primary"
                  @click="clipboardHandle('Tech name', popupInfo.name)" /></a>
            </a-tooltip>
          </div>
          <div>
            <div class="d-flex align-items-center">
              <div class="popup-modal__title mr-2" >Activity</div>
            </div>
            <div class="d-flex flex-column">
              <div class="text-uppercase mr-auto mb-1">
                <span :class="{'popup-modal__info-container--danger-text' : !isPopupDateValid}">{{ formatDate(popupInfo.start_date) }} - {{ formatDate(popupInfo.end_date) }}</span>
              </div>
              <span v-if="!isPopupDateValid" class="text-danger">*Incorrect date</span>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="popup-modal__util-width">
            <div class="popup-modal__title">Name</div>
            <span>{{ selectedInfo.title.length > 40 ? selectedInfo.title.slice(0, 40) + '...' :
              selectedInfo.title }}</span>
          </div>
          <div>
            <div class="popup-modal__title">Show limit</div>
            <span>{{ popupInfo.limit }}</span>
          </div>
        </div>
        <div class="d-flex">
          <div class="popup-modal__util-width">
            <div class="popup-modal__title">Pop-up ID</div>
            <span>{{ popupInfo.id }}</span>
          </div>
          <div>
            <div class="popup-modal__title">Banner theme</div>
            <span style="text-transform: capitalize;">{{ selectedInfo.theme }}</span>
          </div>
        </div>
        <div>
          <div class="popup-modal__title">UUID</div>
          <span class="mr-2">{{ popupInfo.uuid }}</span>
          <a-tooltip title="Copy to clipboard" placement="right">
            <a href="javascript:;"><a-icon type="copy" class="font-size-16 text-primary"
                @click="clipboardHandle('UUID', popupInfo.uuid)" /></a>
          </a-tooltip>
        </div>
        <div>
          <div class="popup-modal__title">Text</div>
          <span>{{ selectedInfo.description }}</span>
        </div>
        <div>
          <div class="popup-modal__title">Apps</div>
          <div style="line-height: 2.1rem">
            <a-tag style="margin-right: 15px; padding: 1px 8px; border-radius: 2px"
              v-for="app in popupInfo.apps" :key="app.id">
              {{ app.name }}
              <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="ml-1 text-success" />
              <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="ml-1 text-primary" />
              <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="ml-1 text-default" />
            </a-tag>
          </div>
        </div>
      </div>
    </div>
    <a-row>
      <a-col :span="24">
        <div class="d-flex justify-content-between popup-modal__footer">
          <a-button @click="clipboardHandle('Popup', JSON.stringify(popupInfo, null, 2) )">
              <a-icon type="copy" class="font-size-14" />
              <span>Copy content</span>
            </a-button>
          <div class="ml-auto text-right">
            <a-button @click="openEditPage">
              <a-icon type="edit" class="font-size-14" />
              <span>Edit</span>
            </a-button>
            <a-button type="primary" style="margin-left: 10px;" @click="closeModal">
              OK
            </a-button>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'popInfoModal',
  props: {
    popupInfo: {
      type: Object,
    },
    sectionId: {
      type: Number,
    },
    activeTabId: {
      type: Number,
    },
    languages: {
      type: Array,
    },
    isPopupDateValid: {
      type: Boolean,
      default: true,
    },
    isViewscreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedInfo() {
      return this.popupInfo.localizations[this.selectedLanguage]
    },
    langOptions() {
      const langKeys = Object.keys(this.popupInfo.localizations)
      return this.languages.filter(language => langKeys.includes(language.name)).map(language => ({
        name: language.desc,
        value: language.name,
      }))
    },
    badgeText() {
      return this.isPopupDateValid ? 'Active' : 'Error'
    },
    badgeClass() {
      return this.isPopupDateValid ? 'popup-modal__badge--active' : 'popup-modal__badge--error'
    },
  },
  data: () => ({
    selectedLanguage: 'ru',
  }),
  created() {
  },
  methods: {
    formatDate: function (date) {
      return this.$moment(date).format('DD.MM.YY HH:mm')
    },
    handleLangChange(selectedLangKey) {
      this.selectedLanguage = selectedLangKey
    },
    openEditPage() {
      if (!this.sectionId) {
        return this.$router.push(`/popup-banners/edit/${this.popupInfo.id}`)
      }
      this.$router.push(`/popup-banners/edit/${this.popupInfo.id}/${this.activeTabId}/${this.sectionId}`)
    },
    closeModal() {
      this.$emit('close')
    },
    clipboardHandle(text, info) {
      this.$clipboard(info)
      this.$notification.success({
        message: `${text} successfully copied to clipboard`,
        description: info.slice(0, 50) + '...',
      })
    },
    copyPopupInfo() {
      this.$clipboard(JSON.stringify(this.popupInfo, null, 2))
    },
  },
}
</script>

<style lang="scss">
.popup-modal {
  &__content {
    padding: 0 0 16px;
    display: flex;
    gap: 16px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__image-container {
    position: relative;
    display: inline-block;
    margin: auto 0;
    align-items: center;
  }

  &__image {
    width: 233px;
    height: 362px;
  }

  &__badge {
    position: absolute;
    top: 8px;
    right: 8px;
    color: white;
    padding: 1px 6px;
    border-radius: 4px;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 4px;
    &--active {
      background-color: rgba(20, 174, 92, 1);

      &::before {
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: white;
      display: inline-block;
    }
    }
    &--error{
      background-color: red;
    }
  }

  &__info-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--danger-text {
      border-radius: 3px;
      padding: 1px 3px;
      background-color: rgba(254, 233, 231, 1);
    }
  }

  &__title {
    color: #bfbfbf;
    font-weight: 300;
  }

  &__util-width {
    width: 60%;
  }

  &__footer {
    padding-top: 12px;
    border-top: 1px solid #E8E8E8;
  }

  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 !important;
  }
}
</style>
